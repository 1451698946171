// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-projects-commercial-js": () => import("./../../../src/pages/projects/commercial.js" /* webpackChunkName: "component---src-pages-projects-commercial-js" */),
  "component---src-pages-projects-industrial-js": () => import("./../../../src/pages/projects/industrial.js" /* webpackChunkName: "component---src-pages-projects-industrial-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-residential-js": () => import("./../../../src/pages/projects/residential.js" /* webpackChunkName: "component---src-pages-projects-residential-js" */),
  "component---src-pages-services-commercial-js": () => import("./../../../src/pages/services/commercial.js" /* webpackChunkName: "component---src-pages-services-commercial-js" */),
  "component---src-pages-services-industrial-js": () => import("./../../../src/pages/services/industrial.js" /* webpackChunkName: "component---src-pages-services-industrial-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-residential-js": () => import("./../../../src/pages/services/residential.js" /* webpackChunkName: "component---src-pages-services-residential-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

